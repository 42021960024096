body{
	background-color: #f0f0f0;
}

.ar.login-screen{
	min-height: 75vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;

	.fr.groupper{
		min-height: 400px;
		max-width: 400px;
		width: 100%;
		.head{
			height: auto;
			&.logo{
				justify-content: center;
				padding: 20px 10px;
				> img{
					height: 30px;
				}
			}
			> .fr.header{
				margin: 0;
				width: 100%;
				text-align: center;
				.sub.header{
					font-size: 14px;
					text-transform: none;
				}
			}
		}
		.contents{
			min-height: calc(400px - 44px - 58px - 72px);
			&.full{
				min-height: 360px;
				margin-top: -20px;
				display: flex;
				justify-content: center;
				align-items: center;
				> .fr.header{
					margin: 0;
				}
			}
			&.semi{
				min-height: 300px;
				flex-direction: column;
			}
			// display: flex;
			// flex-direction: column;
			// justify-content: center;

			.forgot{
				text-align: center;
				margin-top: 15px;
				.fr.button{
					color: gray !important;
					font-weight: normal;
					&:hover{
						font-weight: bold;
						color: white !important;
					}
				}
			}
		}

		.ar.captcha{
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 10px;
		}

	}

	&.admin .fr.groupper{
		.head{
			background-color: #151717;
			color: white;
			&.section{
				// border-top: 0;
				margin: -21px;
				margin-bottom: 20px;
				background-color: #f9fafb;
			}
			// .fr.header{
			// 	color: white;
			// 	.sub.header{
			// 		color: darken(white, 20%);
			// 	}
			// }
		}
	}

	&.change{
		min-height: auto;
		.fr.groupper{
			max-width: 500px;
		}
	}

	@media screen and (max-width: 415px){
		display: block;
	}
}